<script setup>
import { defineProps, defineEmits, computed, watch } from "vue";
import { ref } from "vue";
import Multiselect from "vue-multiselect";

const props = defineProps({
  optionsCarrier: Array,
  optionsStates: Array,
  products: Array,
  data: Object,
});

const emit = defineEmits(["update:products"]);
const items = ref(props.data || []); // Cargar ítems desde props
console.log("prosp", items);
const selectedCarrier = ref(props.optionsCarrier);
const selectedStates = ref(props.optionsStates);

let nextId = 1;

const addItem = () => {
  items.value.push({
    id: nextId++,
    carrier: "", // Mantener el carrier vacío para que se complete más tarde
    states: [], // Mantener states como un array vacío
  });
  emit("update:products", items.value);
};

const deleteItem = (id) => {
  const index = items.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    items.value.splice(index, 1);
  }
};

watch(
  () => props.data,
  (newData) => {
    items.value = newData || []; // Actualiza items cuando props.data cambia
  }
);
</script>

<template>
  <div class="carrier-table">
    <div class="table-header">
      <div class="header-cell">Carrier</div>
      <div class="header-cell">Estados</div>
      <div class="header-cell">Acciones</div>
    </div>

    <div class="table-body">
      <div v-for="(item, index) in items" :key="index" class="table-row">
        <select
          v-model="item.carrier"
          class="input-cell"
          placeholder="Nombre del carrier"
        >
          <option
            v-for="carrier in selectedCarrier"
            :key="carrier.value"
            :value="carrier.value"
          >
            {{ carrier.label }}
          </option>
        </select>

        <multiselect
          v-model="item.states"
          :options="selectedStates.map((state) => state.label)"
          :multiple="true"
          placeholder="Select option"
          :searchable="true"
          :clear-on-select="false"
          :close-on-select="false"
          :preserve-search="true"
          class="w-full"
        >
        </multiselect>

        <button
          @click="deleteItem(item.id)"
          class="delete-btn"
          style="text-align: left"
        >
          🗑️
        </button>
      </div>
    </div>

    <button @click="addItem" class="add-btn">+ Agregar item</button>
  </div>
</template>

<style scoped>
@import "vue-multiselect/dist/vue-multiselect.css";
.carrier-table {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.table-header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 80px;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 10px;
  font-weight: bold;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 80px;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.input-cell {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: 38px;
}

.multiselect-cell {
  min-height: 38px;
}

.delete-btn {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-left: 75px;
}

.delete-btn:hover {
  background-color: #cc0000;
}

.add-btn {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.add-btn:hover {
  background-color: #45a049;
}

.state-selector .multiselect {
  min-height: 40px;
}

.state-selector .multiselect__tags {
  min-height: 40px;
  padding: 8px 40px 0 8px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
}

.state-selector .multiselect__select {
  height: 38px;
  width: 38px;
}

.state-selector .multiselect__placeholder {
  padding-top: 0;
  margin-bottom: 0;
  color: #9ca3af;
}

.state-selector .multiselect__input,
.state-selector .multiselect__single {
  margin-bottom: 0;
  padding: 0;
}

.state-selector .multiselect--active {
  z-index: 1000;
}
</style>
