<script setup>
import { Notyf } from "notyf";
import { ref, onMounted, watch, defineProps } from "vue";
import { useSaleStore } from "@/store/sales";
import { getUser, statusColors } from "@/utils/";
import { t, getCurrentLanguage } from "@/utils/i18n";

import { Empty } from "ant-design-vue";
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

const props = defineProps({
  dateRange: {
    type: Object,
    required: true,
  },
});

const roleUser = ref("");
const notyf = new Notyf();
const totCounts = ref([]);
const isLoading = ref(true);
const isEmpty = ref(false);
const isSearchEmpty = ref(false);
const saleStore = useSaleStore();
const chartConfiguration = ref({});

const countTotSales = ref({
  draft: 0,
  assigned: 0,
  rejected: 0,
  corrected: 0,
  submitted: 0,
  to_review: 0,
});

const { draft, assigned, rejected, corrected, submitted, to_review } =
  statusColors;

const optionsChart = {
  chart: {
    id: "vuechart-example",
    fontFamily: "Inter, sans-serif",
    height: 320,
    type: "donut",
    dropShadow: {
      enabled: true,
      enabledSeries: [0],
      top: -2,
      left: 2,
      blur: 5,
      opacity: 0.06,
    },
  },
};

const setDataChart = async (isoDates) => {
  try {
    const user = getUser();
    roleUser.value = user.role;

    const totSalesCount = await saleStore.fetchSalesCounts(isoDates);

    const {
      DRAFT = 0,
      ASSIGNED = 0,
      REJECTED = 0,
      CORRECTED = 0,
      SUBMITTED = 0,
      TO_REVIEW = 0,
    } = totSalesCount;

    countTotSales.value.draft = DRAFT;
    countTotSales.value.assigned = ASSIGNED;
    countTotSales.value.rejected = REJECTED;
    countTotSales.value.corrected = CORRECTED;
    countTotSales.value.submitted = SUBMITTED;
    countTotSales.value.to_review = TO_REVIEW;

    let counts, colors, labels;
    if (roleUser.value === "SELLER") {
      counts = [DRAFT, ASSIGNED, REJECTED, CORRECTED, SUBMITTED, TO_REVIEW];
      colors = [draft, assigned, rejected, corrected, submitted, to_review];
      labels = [
        "Draft",
        "Assigned",
        "Rejected",
        "Corrected",
        "Submitted",
        "To review",
      ];
    } else {
      counts = [ASSIGNED, REJECTED, CORRECTED, SUBMITTED, TO_REVIEW];
      colors = [assigned, rejected, corrected, submitted, to_review];
      labels = ["Assigned", "Rejected", "Corrected", "Submitted", "To review"];
    }

    const total = counts.reduce((sum, value) => sum + value, 0);

    if (total > 0) {
      isEmpty.value = false;
      optionsChart.colors = colors;
      optionsChart.labels = labels;
      totCounts.value = counts;
      chartConfiguration.value = optionsChart;
    } else {
      isEmpty.value = true;
    }

    isLoading.value = false;
  } catch (err) {
    const error = saleStore.error;

    let errorMessage = "Something went wrong. Please try again later.";

    if (error.response) {
      switch (error.response.status) {
        case 404:
          errorMessage = "Data not found. Please try a different query.";
          isLoading.value = false;
          isEmpty.value = true;
          isSearchEmpty.value = true;
          break;
        case 500:
          errorMessage = "Server error. Please try again later.";
          break;
        default:
          errorMessage = "Unexpected error occurred. Please try again.";
      }
    } else if (error.request) {
      errorMessage =
        "No response from the server. Please check your network connection.";
    }

    notyf.error(errorMessage);
  }
};

watch(
  () => props.dateRange,
  (newDateRange) => {
    isLoading.value = true;
    setDataChart(newDateRange);
  }
);

onMounted(async () => {
  setDataChart({ startDate: null, endDate: null, filterDate: "createdAt" });
});
</script>

<template>
  <div class="card flex-fill">
    <div class="card-header border-0">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title">{{ t("dashboard.text2") }}</h5>
      </div>
    </div>
    <div class="card-body apexchart-index pt-0">
      <div class="border-dotted mb-3"></div>
      <div
        class="d-flex justify-content-center align-items-center h-100"
        style="min-height: 473px"
      >
        <div class="w-100">
          <a-spin
            size="large"
            v-if="isLoading"
            class="d-flex justify-content-center"
          />
          <a-empty
            :image="simpleImage"
            v-show="isEmpty && !isLoading"
            class="mx-auto a-empty-wrap"
          />
          <apexchart
            type="donut"
            height="350"
            :options="chartConfiguration"
            :series="totCounts"
            v-if="!isEmpty && !isLoading"
          ></apexchart>

          <div class="text-center text-muted status-sales" v-show="!isLoading">
            <div class="row d-flex justify-content-between count-tot-sales">
              <div class="col-2" v-if="roleUser === 'SELLER'">
                <div class="mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="fas fa-circle dot-draft me-1"></i>
                    {{ t("dashboard.cards.draft") }}
                  </p>
                  <h5>{{ countTotSales.draft }}</h5>
                </div>
              </div>
              <div class="col-2">
                <div class="mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="fas fa-circle dot-assigned me-1"></i>
                    {{ t("dashboard.cards.assigned") }}
                  </p>
                  <h5>{{ countTotSales.assigned }}</h5>
                </div>
              </div>
              <div class="col-2">
                <div class="mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="fas fa-circle dot-rejected me-1"></i>
                    {{ t("dashboard.cards.rejected") }}
                  </p>
                  <h5>{{ countTotSales.rejected }}</h5>
                </div>
              </div>
              <div class="col-2">
                <div class="mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="fas fa-circle dot-corrected me-1"></i>
                    {{ t("dashboard.cards.corrected") }}
                  </p>
                  <h5>{{ countTotSales.corrected }}</h5>
                </div>
              </div>
              <div class="col-2">
                <div class="mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="fas fa-circle dot-submitted me-1"></i>
                    {{ t("dashboard.cards.submitted") }}
                  </p>
                  <h5>{{ countTotSales.submitted }}</h5>
                </div>
              </div>
              <div class="col-2">
                <div class="mt-4">
                  <p class="mb-2 text-truncate">
                    <i class="fas fa-circle dot-to_review me-1"></i>
                    {{ t("dashboard.cards.to_review") }}
                  </p>
                  <h5>{{ countTotSales.to_review }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.card-body {
  height: 84%;
}

.btn-filters {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 8px;
  color: #878a99;
  min-width: 39px;
  height: 39px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease;
}

.daterangepicker-wrap input {
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
}

.daterangepicker-wrap {
  padding: 0;
  width: 45%;
}

.calendar-icon {
  position: absolute;
  top: 36px;
  right: 30px;
  color: #878a99;
}

.btn-filters:hover {
  border: 1px solid #44044b;
  transition: all 0.4s ease;
}

.btn-filters:focus {
  border: 1px solid #44044b;
  transition: all 0.4s ease;
}

.btn-filters:hover + .calendar-icon {
  color: #44044b;
}

.btn-filters:focus + .calendar-icon {
  color: #44044b;
}

.count-tot-sales .col-2 {
  min-width: 20%;
  padding: 0;
}

.a-empty-wrap {
  margin-bottom: 200px;
  margin-top: 100px;
}

.status-sales {
  margin-top: 100px;
}
</style>
