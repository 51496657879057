<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps } from "vue";
import { CloudUploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { useSaleStore } from "@/store/sales";
import { useRoute } from "vue-router";
import { t } from "@/utils/i18n";

const fileList = ref([]);
const saleStore = useSaleStore();
const route = useRoute();
const uid = route.params.uid;
const uploadedFiles = ref([]);
const selectedIndex = ref(null);
const selectedFile = ref(null);

const salesData = ref({
  observations: "", // Valor inicial
});

const props = defineProps({
  saleData: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["validationChange"]);

const handleFileChange = (info) => {
  fileList.value = info.fileList;
  saleStore.updateSection("attachments", fileList.value);

  emit("updateSaleData", { attachments: fileList.value });
};

const handleUpdateObservations = (observations) => {
  saleStore.updateObservations(observations);
};

const compressFile = async (file) => {
  // If file is not an image or is smaller than 1MB, return original file
  if (!file.type.startsWith("image/") || file.size <= 1024 * 1024) {
    return file;
  }

  // Load the image compression library
  const imageCompression = await import("browser-image-compression");

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression.default(file, options);
    console.log("Compression complete:", {
      originalSize: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
      compressedSize: `${(compressedFile.size / 1024 / 1024).toFixed(2)} MB`,
    });
    return compressedFile;
  } catch (error) {
    console.error("Error compressing file:", error);
    return file;
  }
};

const customRequest = async ({ file, onSuccess, onError }) => {
  try {
    const fileToUpload = await compressFile(file);
    const formData = new FormData();
    formData.append("file", fileToUpload);
    const response = await saleStore.addSaleFile(formData, uid);
    onSuccess(response, file);
    message.success(`${file.name} file uploaded successfully.`);
    addToUploadedFiles(file, response);
  } catch (error) {
    console.error("Upload error:", error);
    onError(error);
    message.error(`${file.name} file upload failed.`);
  }
};

const loadExistingFiles = async () => {
  try {
    const files = await saleStore.getSaleAttachments(uid);
    uploadedFiles.value = files.map((file) => ({
      name: file.name,
      type: file.type,
      date: new Date(file.created_at)
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
        .toUpperCase(),
      url: file.url,
      id: file.uid,
      created_at: file.created_at,
    }));
  } catch (error) {
    console.error("Error loading existing files:", error);
    message.error("Failed to load existing files.");
  }
};

const saleById = async () => {
  try {
    const sales = await saleStore.fetchSaleById(uid);
    salesData.value = sales;
  } catch (error) {
    notyf.error("Error loading users");
  }
};

const beforeUpload = (file) => {
  const isFileSizeValid = file.size / 1024 / 1024 < 50;
  const allowedTypes = [
    ".pdf",
    ".PDF",
    ".mp3",
    ".MP3",
    ".jpg",
    ".JPG",
    ".png",
    ".PNG",
    ".jpeg",
    ".JPEG",
    ".mp4",
    ".MP4",
  ];
  const fileExtension = "." + file.name.split(".").pop().toLowerCase();
  const isFileTypeValid = allowedTypes.includes(fileExtension);

  if (!isFileSizeValid) {
    message.error("File must be smaller than 50MB!");
  }
  if (!isFileTypeValid) {
    message.error(`File type ${fileExtension} is not allowed.`);
  }
  return isFileSizeValid && isFileTypeValid;
};

const addToUploadedFiles = (file) => {
  const newFile = {
    name: file.name,
    type: "." + file.name.split(".").pop().toLowerCase(),
    date: new Date()
      .toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })
      .toUpperCase(),
    url: URL.createObjectURL(file),
  };
  uploadedFiles.value.push(newFile);
};

const setFileForDeletion = (index, file, uid_) => {
  console.log(index, file, uid_);
  selectedIndex.value = index;
  selectedFile.value = file;
};

const removeFile = async () => {
  try {
    const selectedFileDelete = uploadedFiles.value[selectedIndex.value];
    await saleStore.filesDelete(uid, selectedFileDelete.id);

    uploadedFiles.value.splice(selectedIndex.value, 1);

    selectedIndex.value = null;
    selectedFile.value = null;

    message.success("File deleted successfully");
  } catch (error) {
    console.error("Error removing file:", error);
    message.error("Failed to remove file.");
  }
};

const previewFile = (file) => {
  if (file.type === ".pdf") {
    window.open(file.url, "_blank");
  } else if (file.type === ".mp3") {
    const audio = new Audio(file.url);
    audio.play();
  } else if (file.type === ".mp4") {
    const audio = new Audio(file.url);
    audio.play();
  } else if (
    file.type === ".jpg" ||
    file.type === ".JPG" ||
    file.type === ".jpeg" ||
    file.type === ".JPEG" ||
    file.type === ".png" ||
    file.type === ".PNG"
  ) {
    const img = new Image();
    img.src = file.url;
    const w = window.open("");
    w.document.write(img.outerHTML);
  } else {
    message.error("Preview not available for this file type.");
  }
};

const isValidToContine = computed(() => {
  const hasPdf = uploadedFiles.value.some(
    (file) =>
      file.type === ".pdf" ||
      file.type === "pdf" ||
      file.type === ".png" ||
      file.type === "png" ||
      file.type === "jpg" ||
      file.type === ".jpg" ||
      file.type === "jpeg" ||
      file.type === ".jpeg"
  );
  const hasMp3 = uploadedFiles.value.some(
    (file) =>
      file.type === ".mp3" ||
      file.type === "mp3" ||
      file.type === ".mp4" ||
      file.type === "mp4"
  );
  return hasPdf && hasMp3;
});

const observations = computed({
  get: () => salesData.value.observations || "",
  set: (newValue) => {
    salesData.value.observations = newValue;
    handleUpdateObservations(newValue);
  },
});
onMounted(saleById);

onMounted(() => {
  loadExistingFiles();
});

watch(isValidToContine, (newValue) => {
  emit("validationChange", newValue);
});
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body container-form">
            <h5 class="card-title">
              {{ t("sales.details.supporting.document") }}
            </h5>
            <div class="file-upload-table">
              <p class="mb-4">
                {{ t("sales.details.supporting.txt") }}
              </p>
              <a-upload-dragger
                v-model:fileList="fileList"
                name="file"
                :multiple="true"
                :beforeUpload="beforeUpload"
                @change="handleFileChange"
                :customRequest="customRequest"
              >
                <p class="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p class="ant-upload-text">
                  <a class="click-upload">Click to upload</a> or drag and drop
                  files here
                </p>
                <p class="ant-upload-hint">
                  Maximum file size 50 MB. <br />Allowed file types: .pdf, .mp3,
                  .jpg
                </p>
              </a-upload-dragger>
              <div class="table-container">
                <table class="table table-striped mt-4">
                  <thead>
                    <tr>
                      <th>{{ t("sales.details.supporting.fileName") }}</th>
                      <th>{{ t("sales.details.supporting.fileType") }}</th>
                      <th>{{ t("sales.details.supporting.date") }}</th>
                      <th>{{ t("sales.details.supporting.action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) in uploadedFiles" :key="index">
                      <td>{{ file.name }}</td>
                      <td>{{ file.type }}</td>
                      <td>{{ file.date }}</td>
                      <td>
                        <i
                          class="fas fa-eye me-3"
                          @click="previewFile(file)"
                        ></i>
                        <i
                          class="fas fa-trash me-3 text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#FileDelate"
                          @click="setFileForDeletion(index, file, uid)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div>
              <h5 class="card-title">
                {{ t("sales.details.supporting.observations") }}
              </h5>
              <div class="col-sm-12">
                <a-textarea
                  class="col-sm-12"
                  v-model:value="observations"
                ></a-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="FileDelate"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <img
            src="@/assets/img/cancel-img.png"
            alt="Declined Icon"
            class="img-fluid mb-4"
          />
          <p class="mb-0">Do you want to delete the document</p>
          <p class="mb-4">
            "<span style="color: #57015f"> Consentimiento informado </span>"?
          </p>

          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="removeFile(index)"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.file-name-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #57015f;
}

.file-quotes {
  color: #000;
}

.anticon svg {
  fill: #44044b;
}

:where(.css-dev-only-do-not-override-1hsjdkk).ant-upload-wrapper
  .ant-upload-drag
  p.ant-upload-drag-icon
  .anticon {
  color: #44044b !important;
}

:where(.css-dev-only-do-not-override-1hsjdkk).ant-upload-wrapper
  .ant-upload-drag {
  background: transparent !important;
  border: 1px dashed #44044b !important;
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  color: #c6cbd1 !important;
}

.table thead {
  color: #fff;
  background: #44044b;
}

th {
  color: white !important;
}

.click-upload,
.ant-upload-text {
  font-weight: bold;
}

.click-upload {
  color: #895e8b;
  text-decoration: underline;
}

.fas {
  cursor: pointer;
}
</style>
