import { defineStore } from "pinia";
import { formatDateToISO } from "@/utils/";
import axios from "@/services/axiosService";
import { sortSalesByDate, classifySalesByStatus } from "@/utils/salesProcessor";
import { BCardBody } from "bootstrap-vue";
import { Notyf } from "notyf";

const notyf = new Notyf();

export const useSaleStore = defineStore("sale", {
  state: () => ({
    saleData: {
      uid: "",
      step: 0,
      saleId: "",
      observations: "",
      personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        gender: null,
        socialSecurityNumber: null,
        inmigrationStatus: null,
        phone: "",
        email: "",
        address: "",
        zipCode: null,
        state: null,
        assignedToName: "",
        sellerFullName: "",
        householdIncome: "",
        married: false,
        requestAccountingServices: false,
        familySize: "",
        comments: "",
        accepted: false,
      },
      income: {
        incomeType: "",
        employerName: "",
        annualIncome: null,
        employersPhone: null,
      },
      members: [],
      planInformation: {
        carrier: "",
        plan: "",
      },
      payment: {
        cardNumber: "",
        expirationDate: "",
        cvv: 0,
        cardholderName: "",
      },
    },
    isLoading: true,
    error: null,
    uidList: [],
    sales: [],
    salesByStatus: {
      DRAFT: [],
      ASSIGNED: [],
      SUBMITTED: [],
      REJECTED: [],
      CORRECTED: [],
    },
  }),
  actions: {
    restorePreviousStepData() {
      if (this.stepHistory && this.stepHistory.length > 0) {
        const previousStepData = this.stepHistory.pop();
        this.saleData = { ...this.saleData, ...previousStepData };
      }
    },

    updateStep(step) {
      if (!this.stepHistory) this.stepHistory = [];
      this.stepHistory.push({ ...this.saleData });
      this.saleData.step = step;
    },

    async registerSale(saleData) {
      try {
        if (!saleData) {
          throw new Error("Sale data is required");
        } else {
          this.isLoading = true;
          const token = localStorage.getItem("token");
          const response = await axios.post("/sales", saleData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.saleData = response.data;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSales(path) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(path, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const sales = response.data;

        // order sales by date of creation
        this.sales = sortSalesByDate(sales);

        // classify sales by status
        this.salesByStatus = classifySalesByStatus(this.sales);

        // Array[all sales, sales by status]
        return [this.sales, this.salesByStatus];
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },

    async updateApproveDecline(uid, body) {
      try {
        this.isLoading = true;

        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append("isAccepted", body.isAccepted);
        formData.append("salesId", body.salesId);
        formData.append("role", body.role);
        formData.append("rejectedBy", body.role);

        if (!body.isAccepted) {
          formData.append("reason", body.reason);
          if (body.file) {
            formData.append("file", body.file);
          }
        }
        console.log("abody", formData);
        const response = await axios.post(
          `/sales/accept-or-decline/${uid}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const updateApproveDecline = response.data;
        this.approved = updateApproveDecline;
        return this.approved;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchSalesByRole(role) {
      let path = "";

      switch (role) {
        case "ADMIN":
          path = "/sales/";
          break;
        case "SUPERVISOR":
          path = "/sales/";
          break;
        case "SELLER":
          path = "/sales/my-sales/SELLER";
          break;
        case "PROCESSOR":
          path = "/sales/my-sales/PROCESSOR";
          break;
        default:
          console.log("Invalid role");
          return;
      }

      // Fetch sales with the path corresponding to the role
      return await this.fetchSales(path);
    },
    async isSaleExisting(phone) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/sales/validate/${phone}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },

    async updateCorrected(uid) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.post(`/sales/correct-sale/${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const saleApprove = response.data;
        this.saleData = saleApprove;
        return this.saleData;
      } catch (error) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchSaleById(uid) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get(`/sales/${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const saleData = response.data;
        this.saleData = saleData;
        return this.saleData;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async updatedSale(saleData) {
      try {
        if (!saleData) {
          throw new Error("Sale data is required");
        } else {
          this.isLoading = true;
          const token = localStorage.getItem("token");
          const response = await axios.put(`/sales/${saleData.uid}`, saleData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          this.saleData = response.data;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },

    async updatedMembersSale(memberAfter, memberBefore, uid) {
      try {
        if (!memberBefore) {
          throw new Error("Sale data is required");
        } else {
          this.isLoading = true;
          const token = localStorage.getItem("token");
          const body = {
            membersAfter: memberAfter[0],
            membersBefore: memberBefore,
          };
          const response = await axios.post(
            `/sales/updateMembers/${uid}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          this.saleData = response.data;
          notyf.success("Update member");
        }
      } catch (err) {
        this.handleError(err);
        notyf.error(err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async addSaleFile(fileData, uid) {
      try {
        if (!fileData) {
          throw new Error("Sale data is required");
        } else {
          this.isLoading = true;
          const token = localStorage.getItem("token");

          const response = await axios.post(`/sales/upload/${uid}`, fileData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          this.saleFiles = response.data;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    updateSaleData(updatedSaleData) {
      // Update each section of saleData individually
      for (const [key, value] of Object.entries(updatedSaleData)) {
        if (typeof value === "object" && value !== null) {
          this.saleData[key] = {
            ...this.saleData[key],
            ...value,
          };
        } else {
          this.saleData[key] = value;
        }
      }
    },
    async updateSaleCompleted(step, uid) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const stepCompleted = {
          step,
        };
        const response = await axios.put(`/sales/${uid}`, stepCompleted, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const saleData = response.data;
        this.saleData = saleData;
        return this.saleData;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    updateField(section, field, value) {
      if (this.saleData[section]) {
        if (field === "birthDate") value = formatDateToISO(value);
        this.saleData[section][field] = value;
      } else {
        console.error(`Section ${section} does not exist in saleData`);
      }
    },
    updateSection(section, data) {
      this.saleData[section] = data;
    },

    updateStep(newStep) {
      this.saleData.step = newStep;
    },
    updateMembers(members) {
      if (members.length > 0) {
        this.saleData.members = members;
        this.updatedSale(this.saleData);
      }
    },

    updateObservations(observations) {
      if (observations) {
        this.saleData.observations = observations;
        this.updatedSale(this.saleData);
      }
    },
    updateMember(index, updatedFields) {
      // Validate if the index is within bounds
      if (index < 0 || index >= this.saleData.members.length) {
        console.error("Index out of range");
        return;
      }

      // Create a new copy of the member with updated fields
      this.saleData.members[index] = {
        ...this.saleData.members[index], // Keep existing fields
        ...updatedFields, // Overwrite with updated fields
      };
    },
    setAnnualIncome(value) {
      this.saleData.income.annualIncome = value;
    },

    async getSaleAttachments(uid) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get(`/sales/saleFiles/${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const saleDataAttachments = response.data;
        this.saleDataAttachments = saleDataAttachments;
        return this.saleDataAttachments;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async getSaleLogs(uid) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get(`/sales/${uid}/logs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const saleDataLogs = response.data;
        this.saleDataLogs = saleDataLogs;
        return this.saleDataLogs;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleError(err) {
      if (err.response) {
        this.error = err.response.data;
      } else if (err.request) {
        this.error = "No response from server";
      } else {
        this.error = "Error setting up the request";
      }
      console.error("Error in sale store:", this.error.message);
    },
    resetSaleData() {
      this.saleData = {
        uid: "",
        step: 0,
        saleId: "",
        personalInfo: {
          firstName: "",
          middleName: "",
          lastName: "",
          birthDate: "",
          gender: null,
          socialSecurityNumber: null,
          inmigrationStatus: "",
          phone: "",
          email: "",
          address: "",
          zipCode: null,
          state: null,
          assignedToName: "",
          sellerFullName: "",
          householdIncome: "",
          married: false,
          requestAccountingServices: false,
          familySize: "",
          comments: "",
          accepted: false,
        },
        income: {
          incomeType: "",
          employerName: "",
          annualIncome: null,
          employersPhone: null,
        },
        members: [],
        planInformation: {
          carrier: "",
          plan: "",
        },
        payment: {
          cardNumber: null,
          expirationDate: "",
          cvv: null,
          cardholderName: "",
        },
      };
    },
    async fetchSalesCounts({ startDate, endDate, filterDate }) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");

        let param = "";
        if (startDate && endDate) {
          param += `?startDate=${startDate}&endDate=${endDate}`;
        }
        if (filterDate) {
          param += param
            ? `&filterDate=${filterDate}`
            : `?filterDate=${filterDate}`;
        }
        const response = await axios.get(`/sales/status/total-counts${param}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data[0];
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async filesDelete(uid, fileId) {
      if (!uid || !fileId) {
        throw new Error("Both uid and fileId are required");
      }

      try {
        this.isLoading = true;

        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("Authentication token is missing");
        }

        const response = await axios.post(
          `/sales/files/delete/${uid}/${fileId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return response.data;
      } catch (err) {
        console.error("Error in filesDelete:", {
          message: err.message,
          status: err.response?.status,
          data: err.response?.data,
        });
        this.handleError(err);
        throw err;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchCarriersTotal() {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get("/sales/carriers/total", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchMonthlySalesSummary() {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get("/sales/filter/month/total", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCompanyTotalSales({ startDate, endDate }) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const param =
          startDate && endDate
            ? `?startDate=${startDate}&endDate=${endDate}`
            : "";

        const response = await axios.get(
          `/companies/filter/company/total${param}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return response.data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async reasignedSales(saleIds, assignedTo) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const body = {
          assignedTo,
          saleId: Array.isArray(saleIds) ? saleIds : [saleIds],
        };

        const response = await axios.post("/sales/reassign", body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        return response.data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSalesByDateRangeAndStatus(
      { startDate, endDate, filterDate },
      filters
    ) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `/sales/filters/default?startDate=${startDate}&endDate=${endDate}&filterDate=${filterDate}&${filters}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.sales = sortSalesByDate(response.data.sales);
        this.salesByStatus = classifySalesByStatus(response.data.sales);
        this.totalSales = response.data.totalSales;
        return [this.sales, this.salesByStatus, this.totalSales];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    updateUidList(uidList) {
      this.uidList = uidList;
    },
    resetUidList() {
      this.uidList = [];
    },
  },
});
