<script setup>
import { ref, watch, onMounted, defineEmits, computed } from "vue";
import { useRouter } from "vue-router";
import {
  formatDate,
  formatDateFromTimestamp,
  getFullName,
  capitalize,
  formatToISODate,
  formatToCreatedAt,
  formatPhoneNumber,
} from "@/utils";
import { useUserStore } from "@/store/user";
import { useSaleStore } from "@/store/sales";
import { t, getCurrentLanguage } from "@/utils/i18n";

const router = useRouter();
const userStore = useUserStore();
const saleStore = useSaleStore();
const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;
const carrierNames = ref({});
const carrierFilters = ref([]);
const companyFilters = ref([]);
const stateFilters = ref([]);
const companyNames = ref({});
const sellerFilters = ref([]);
const processorFilters = ref([]);
const calidadFilters = ref([]);
const uidList = ref(saleStore.uidList);
const loading = ref(true);

const emit = defineEmits(["onSalesReassignRequested"]);

// Computed properties for translations
const translatedStatuses = computed(() => {
  let basicStatuses = [
    { text: t("dashboard.cards.assigned"), value: "ASSIGNED" },
    { text: t("dashboard.cards.rejected"), value: "REJECTED" },
    { text: t("dashboard.cards.corrected"), value: "CORRECTED" },
    { text: t("dashboard.cards.submitted"), value: "SUBMITTED" },
    { text: t("dashboard.cards.to_review"), value: "TO_REVIEW" },
  ];

  if (userRole === "SELLER") {
    basicStatuses = [
      { text: t("dashboard.cards.draft"), value: "DRAFT" },
      ...basicStatuses,
    ];
  }

  return basicStatuses;
});

// Computed columns with translations
const baseColumns = computed(() => [
  {
    title: "ID",
    dataIndex: "saleId",
    key: "saleId",
  },
  {
    title: t("dashboard.table.name"),
    dataIndex: "firstName",
    key: "name",
  },
  {
    title: t("dashboard.table.socialSecurityNumber"),
    dataIndex: "socialSecurityNumber",
    key: "socialSecurityNumber",
  },
  {
    title: t("dashboard.table.status"),
    dataIndex: "status",
    key: "status",
    filters: translatedStatuses.value,
    filterMultiple: true,
    onFilter: (value, record) => record.status === value,
  },
  {
    title: t("dashboard.table.birthDate"),
    dataIndex: "birthDate",
    key: "birthDate",
  },
  {
    title: t("dashboard.table.carrier"),
    dataIndex: "carrier",
    key: "carrier",
    filters: carrierFilters.value,
    onFilter: (value, record) => record.carrier === value,
  },
  {
    title: t("dashboard.table.company"),
    dataIndex: ["sellerCompanyName"],
    key: "sellerCompanyName",
    filters: companyFilters.value,
    onFilter: (value, record) => record.sellerCompanyName === value,
  },
  {
    title: t("dashboard.table.creation"),
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: t("dashboard.table.email"),
    dataIndex: "email",
  },
  {
    title: t("dashboard.table.phone"),
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: t("dashboard.table.state"),
    dataIndex: "state",
    key: "state",
    filters: stateFilters.value,
    onFilter: (value, record) => record.state === value,
  },
]);

// Computed properties for role-specific columns
const sellerColumn = computed(() => ({
  title: t("dashboard.table.seller"),
  dataIndex: "sellerFullName",
  key: "sellerFullName",
  filters: sellerFilters.value,
  onFilter: (value, record) => record.sellerFullName === value,
  filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
}));

const calidadColumn = computed(() => ({
  title: t("dashboard.table.calidad"),
  dataIndex: "assignedToCalidadName",
  key: "assignedToCalidadName",
  filters: calidadFilters.value,
  onFilter: (value, record) => record.assignedToCalidadName === value,
  filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
}));

const processorColumn = computed(() => ({
  title: t("dashboard.table.processor"),
  dataIndex: "assignedToName",
  key: "assignedToName",
  filters: processorFilters.value,
  onFilter: (value, record) => record.assignedToName === value,
  filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
}));

// Computed final columns based on user role
const columns = computed(() => {
  let finalColumns = [...baseColumns.value];

  switch (userRole) {
    case "SELLER":
      finalColumns.push(processorColumn.value);
      finalColumns.push(calidadColumn.value);
      break;
    case "PROCESSOR":
      finalColumns.push(sellerColumn.value);
      finalColumns.push(calidadColumn.value);
      break;
    default:
      finalColumns.push(
        sellerColumn.value,
        calidadColumn.value,
        processorColumn.value
      );
  }

  return finalColumns;
});

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  activeKey: {
    type: String,
    default: "",
  },
  salesTotal: {
    type: Number,
    default: 0,
  },
});

const totalSales = computed(() => {
  return props.salesTotal || 0;
});

function handDetails(record) {
  if (record.status === "DRAFT") {
    router.push(`/sales/new-sale/${record.document_id}`);
  } else {
    router.push(`/sales/sale-detail?${record.document_id}`);
  }
}

function stopLoader() {
  setTimeout(() => {
    loading.value = false;
  }, 1000);
}

const fetchCarrierNames = async () => {
  try {
    const carriers = await userStore.fetchCarrier();
    if (carriers && carriers.length > 0) {
      carriers[0].list.forEach((carrier) => {
        carrierNames.value[carrier.id] = carrier.value;
      });
    }

    carrierFilters.value = carriers[0].list.map((carrier) => ({
      text: carrier.value,
      value: carrier.id,
    }));
  } catch (error) {
    console.error("Error fetching carrier names:", error);
  }
};
const fetchCompanyNames = async () => {
  try {
    const companiesProxy = await userStore.fetchCompanies();

    if (companiesProxy && companiesProxy.length > 0) {
      const companies = Array.from(companiesProxy[0]).map((company) => {
        return {
          value: company.id,
          text: company.value,
        };
      });

      companyFilters.value = companies;
    } else {
      companyFilters.value = [];
    }
  } catch (error) {
    companyFilters.value = [];
  }
};

const states = [
  { value: "Florida", text: "Florida" },
  { value: "Alabama", text: "Alabama" },
  { value: "Arizona", text: "Arizona" },
  { value: "Georgia", text: "Georgia" },
  { value: "Illinois", text: "Illinois" },
  { value: "Louisiana", text: "Louisiana" },
  { value: "Missouri", text: "Missouri" },
  { value: "North Carolina", text: "North Carolina" },
  { value: "Ohio", text: "Ohio" },
  { value: "South Carolina", text: "South Carolina" },
  { value: "Tennessee", text: "Tennessee" },
  { value: "Texas", text: "Texas" },
];
stateFilters.value = states;

const fullName = (firstName, lastName) => `${firstName} ${lastName}`;

const loadUsersList = async () => {
  try {
    const users = await userStore.fetchUsers();

    processorFilters.value = users
      .filter((user) => user.role === "PROCESSOR")
      .map((user) => ({
        text: fullName(user.firstName, user.lastName),
        value: fullName(user.firstName, user.lastName),
      }));

    calidadFilters.value = users
      .filter((user) => user.role === "CALIDAD")
      .map((user) => ({
        text: fullName(user.firstName, user.lastName),
        value: fullName(user.firstName, user.lastName),
      }));

    sellerFilters.value = users
      .filter((user) => user.role === "SELLER")
      .map((user) => ({
        text: fullName(user.firstName, user.lastName),
        value: fullName(user.firstName, user.lastName),
      }));
  } catch (error) {
    console.error("Error loading users", error);
  }
};

const handleSalesReassign = (event, uid) => {
  const isChecked = event.target.checked;
  if (isChecked) {
    uidList.value.push(uid);
  } else {
    const index = uidList.value.indexOf(uid);
    uidList.value.splice(index, 1);
  }
  saleStore.updateUidList(uidList.value);
  emit("onSalesReassignRequested", saleStore.uidList);
};

const isChecked = (uid) => uidList.value.includes(uid);

// Watch for data changes
watch(
  () => props.data,
  (newData) => {
    if (newData) {
      loading.value = true;
      fetchCarrierNames();
      fetchCompanyNames();
      stopLoader();
    }
  }
);

// Watch for language changes
watch(getCurrentLanguage, () => {
  loading.value = true;
  stopLoader();
});

onMounted(() => {
  fetchCarrierNames();
  loadUsersList();
});
</script>

<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="table-responsive">
        <a-config-provider
          :theme="{
            token: {
              colorPrimary: '#44044b',
            },
          }"
        >
          <a-table
            :columns="columns"
            :loading="loading"
            :data-source="props.data"
            class="table-primary"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'saleId'">
                <label
                  v-if="
                    ([
                      'ASSIGNED',
                      'REJECTED',
                      'CORRECTED',
                      'SUBMITTED',
                    ].includes(record.status) &&
                      userRole === 'ADMIN') ||
                    userRole === 'SUPERVISOR' ||
                    userRole === 'JCALIDAD'
                  "
                  class="custom_check"
                >
                  <input
                    type="checkbox"
                    name="sales"
                    :disabled="['SUBMITTED'].includes(record.status)"
                    :data-sale-id="record.saleId"
                    :checked="isChecked(record.document_id)"
                    @click="
                      (event) => handleSalesReassign(event, record.document_id)
                    "
                  />
                  <span class="checkmark"></span>
                </label>

                <router-link
                  v-if="record.saleId"
                  to="/sale-detail"
                  @click="handDetails(record)"
                  class="invoice-link"
                >
                  {{ record.saleId }}
                </router-link>
              </template>

              <template v-if="column.key === 'name'">
                {{
                  record && record.firstName
                    ? `${record.firstName} ${
                        record.middleName ? record.middleName + " " : ""
                      }${record.lastName}`
                    : "Nombre no disponible"
                }}
              </template>
              <template v-if="column.key === 'birthDate'">
                {{
                  record && record.birthDate
                    ? formatDate(record.birthDate)
                    : "Date not available"
                }}
              </template>

              <template v-if="column.key === 'carrier'">
                {{ carrierNames[record.carrier] || "" }}
              </template>

              <template v-if="column.key === 'phone'">
                {{
                  record.phone
                    ? formatPhoneNumber(record.phone)
                    : "Date not available"
                }}
              </template>
              <template v-if="column.key === 'createdAt'">
                {{
                  record.createdAt
                    ? formatToCreatedAt(record.createdAt)
                    : "Date not available"
                }}
              </template>
              <template v-else-if="column.key === 'status'">
                <span
                  v-if="record.status === 'DRAFT'"
                  class="badge bg-warning-light text-warning"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'ASSIGNED'"
                  class="badge bg-info-light text-info"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'CORRECTED'"
                  class="badge bg-light text-secondary"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'SUBMITTED'"
                  class="badge bg-success-light text-success rounded"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'REJECTED'"
                  class="badge bg-danger-light text-danger"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'TO_REVIEW'"
                  class="badge gb-purple-light text-purple"
                >
                  {{ capitalize(record.status) }}
                </span>
              </template>
              <template v-if="column.key === 'sellerFullName'">
                {{
                  record.sellerFullName
                    ? record.sellerFullName
                    : "Seller not assigned"
                }}
              </template>
            </template>
          </a-table>
          <div class="total-sales">
            <strong>
              {{ t("dashboard.table.totalSales") }}: {{ totalSales }}</strong
            >
          </div>
        </a-config-provider>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-warnin-light {
  background-color: #fdb0b0;
  color: #fe0000 !important;
}

input[type="checkbox"][disabled] + .checkmark {
  cursor: initial;
  border: 1px solid #ebebeb;
  background: #f7f7f7;
}
</style>
